.CoreValuesSection {
  background-color: #f33f3a;
  padding: 60px 100px;
  border-radius: 20px;
}
@media only screen and (max-width: 768px) {
  .CoreValuesSection {
    padding: 40px;
  }
}
.CoreValuesSection h2 {
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  color: #fff;
}
.CoreValues {
  display: flex;
  justify-content: space-around;
  gap: 10%;
  margin-top: 40px;
  div {
    max-width: 250px;
    @media only screen and (max-width: 839px) {
      max-width: 100%;
    }
  }
}
@media only screen and (max-width: 768px) {
  .CoreValues {
    flex-direction: column;
    gap: 40px;
  }
}
.CoreValues h3 {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 10px;
}
.CoreValues p {
  font-size: 12px;
  color: #fff;
}
.AboutBtn {
  text-align: center;
  margin-top: 40px;
  a {
    color: #f33f3a;
    padding: 10px 25px;
    font-weight: bold;
    background-color: #fff;
    display: inline-block;
    border-radius: 10px;
    text-align: center;
    &:hover {
      background-color: #ec1e79;
      color: #fff;
      svg {
        fill: #fff;
      }
    }
    @media only screen and (max-width: 839px) {
      width: 100%;
      font-size: 14px;
    }
  }
}
