.ProductCard {
  position: relative;
  line-height: 1.4em;
  border: 1px solid #d9d9d9;
  padding: 20px;
  border-radius: 10px;
  transition: all 0.3s ease-out;

  &:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    background-color: white;
    transform-origin: 50% 50%;
    transition: transform 0.15s ease-out;
    transform: translateY(-0.25rem);
  }
  @media only screen and (max-width: 839px) {
    &:hover {
      transform: translateY(0rem);
      border: 0;
      box-shadow: none;
      top: 0;
    }
  }
}

.ProductCard a {
  color: inherit;
  text-decoration: none;
}

.ProductCardImage {
  background-color: var(--box-background, #fff);
  border-radius: 4px;

  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 204px;
  height: 300px;

  @media screen and (max-width: 839px) {
    width: auto;
    height: 330px;
  }
  @media screen and (max-width: 640px) {
    width: auto;
    height: 270px;
  }
  @media screen and (max-width: 375px) {
    width: auto;
    height: 225px;
  }
  @media screen and (max-width: 320px) {
    width: auto;
    height: 205px;
  }
}

.ProductCardImage:hover img {
  transition: transform 1.6s !important;
  transform: scale3d(1.025, 1.025, 1.025);
}

.ProductCardTag {
  color: #f33f3a;
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid #f33f3a;
  margin-right: 10px;
  border-radius: 20px;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  @media only screen and (max-width: 839px) {
    margin-bottom: 5px;
    margin-right: 5px;
    font-size: 40%;
    padding: 0px 5px;
  }
  @media only screen and (max-width: 375px) {
    font-size: 38%;
  }
}

.ProductCardName {
  color: var(--text-color, #000);
  font-size: 18px;
  font-weight: 600;
  line-height: 1.428;
  @media only screen and (max-width: 839px) {
    font-size: 16px;
    line-height: 1.3em;
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.ProductCardAvailabilityLabel {
  padding: 5px;
  text-align: center;
  color: #fff;
  background: #28b493;
  border-radius: 5px;
  @media screen and (max-width: 839px) {
    display: none;
  }
}
.PosterAvailabilityLabel {
  padding: 5px;
  text-align: center;
  color: #fff;
  background: #28b493;
  border-radius: 5px;
  position: absolute;
  bottom: 10px;
  width: 90%;
  display: none;
  @media screen and (max-width: 839px) {
    display: block;
    font-size: 12px;
  }
}

.ProductCardRating {
  color: #f3e53a;
  font-weight: 700;
}

.ProductCardReviewRating {
  color: var(--text-color-muted);
  font-size: 14.4px;
  @media only screen and (max-width: 839px) {
    display: none;
  }
}

.ProductCardRatingStars svg {
  vertical-align: middle;
}

.ProductCardRatingReviews {
  position: relative;
  top: 2px;
}
.CityTourCardDetails {
  padding-bottom: 2px;
  padding-top: 2px;
  p {
    font-size: 14px;
    color: #565c5c;
    margin-bottom: 10px;
    line-height: 1.5em;
    margin-top: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  @media only screen and (max-width: 839px) {
    p {
      display: none;
    }

    white-space: pre-wrap;
  }
}
.ProductCardContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60%;
  @media only screen and (max-width: 839px) {
    width: 100%;
  }
}
.ProductCardContainer {
  display: flex;
  gap: 20px;
  @media only screen and (max-width: 839px) {
    flex-direction: column;
    gap: 10px;
  }
}
.DurationPrice {
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 839px) {
    gap: 5px;
    font-size: 14px;
    flex-direction: column;
  }
}
.DDContainer {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  .ProductCardReviewRating {
    display: none;
  }
  @media only screen and (max-width: 839px) {
    .ProductCardReviewRating {
      display: block;
    }
  }
  @media only screen and (max-width: 640px) {
    font-size: 12px !important;
    .ProductCardReviewRating {
      font-size: 12px !important;
    }
  }
  @media only screen and (max-width: 375px) {
    font-size: 10px !important;
    .ProductCardReviewRating {
      font-size: 10px !important;
    }
  }
}
