@import "../../../styles/layout";

.FeaturedBannersContainer {
  margin-top: 20px;
}
.FeaturedSlideWrapper {
  padding-top: 20px;
}
.FeaturedBanner {
  width: 360px;
  height: 200px;
  position: relative;
  background: #000;
  border-radius: 10px;
  overflow: hidden;

  &:hover {
    transition: all 0.2s ease-out;
    transform-origin: 50% 50%;
    transition: transform 0.15s ease-out;
    opacity: 0.95;
    transform: translateY(-0.25rem);
  }

  @include mq-down(md) {
    width: 100%;
    height: 220px;
  }
  .BackDrop {
    background: linear-gradient(
      #0000007d,
      rgba(13, 13, 13, 0),
      rgb(20 20 20 / 18%)
    );
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 2;
  }
  .FeaturedTitle {
    position: absolute;
    color: #fff;
    font-family: sans-serif;
    top: 0;
    padding: 15px;
    z-index: 2;
    line-height: 1.3em;
    font-weight: bold;
    font-size: 20px;
    @include mq-down(sm) {
      font-size: 16px;
    }
  }
  .CtaText {
    position: absolute;
    color: #fff;
    font-family: sans-serif;
    bottom: 0;
    padding: 15px;
    z-index: 2;
    font-weight: bold;
    font-size: 16px;
    @include mq-down(sm) {
      font-size: 14px;
    }
  }
}
