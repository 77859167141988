$skeleton-color: #e0e0e0;
$skeleton-light: #f0f0f0;

.Skeleton {
  display: block;
  background: $skeleton-color;
  border-radius: 4px;
  overflow: hidden;
  position: relative;

  &.shimmer::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -150%;
    width: 150%;
    height: 100%;
    background: linear-gradient(
      90deg,
      $skeleton-color,
      $skeleton-light,
      $skeleton-color
    );
    animation: shimmer 1.5s infinite;
  }

  &.text {
    height: 16px;
    margin: 8px 0;

    &.title {
      width: 70%;
      height: 20px;
    }

    &.description {
      width: 90%;
      height: 14px;
    }

    &.price {
      width: 40%;
      height: 18px;
    }
  }

  &.rect {
    width: 100%;
    height: 150px;
  }

  &.circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}

.ProductCardSkeleton {
  width: 270px;
  flex-shrink: 0;
}

.SkeletonProductList {
  display: flex;
  white-space: nowrap;
  overflow-x: scroll;
  gap: 10px;
  margin-top: 20px;
}

.SkeletonCalendar {
  display: flex;
  flex-direction: column;
  width: 280px;
  padding: 16px;
  background-color: $skeleton-light;
  border: 1px solid $skeleton-color;
  border-radius: 8px;
  gap: 8px;
  position: relative;
  overflow: hidden;

  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;

    .spinner {
      border: 4px solid #f44336;
      border-top: 4px solid transparent;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      animation: spin 1s linear infinite;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    .month {
      height: 20px;
      width: 140px;
      background: $skeleton-color;
      border-radius: 4px;
    }

    .navigation {
      display: flex;
      gap: 8px;

      .arrow {
        width: 24px;
        height: 24px;
        background: $skeleton-color;
        border-radius: 50%;
      }
    }
  }

  .weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 4px;

    .weekday {
      height: 16px;
      background: $skeleton-color;
      border-radius: 4px;
    }
  }

  .dates {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 4px;

    .date {
      height: 32px;
      background: $skeleton-color;
      border-radius: 4px;
      position: relative;

      &.shimmer {
        background: linear-gradient(
          90deg,
          $skeleton-color 25%,
          $skeleton-light 50%,
          $skeleton-color 75%
        );
        background-size: 200% 100%;
        animation: shimmer-static 3s infinite linear;
      }
    }
  }
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
@keyframes shimmer-static {
  0% {
    background-position: -200% 0;
  }
  50% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
